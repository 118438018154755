import React, { useEffect } from 'react';
// Structure imports
import { KfAnchorLink } from '@klickinc/kf-react-components';
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import Hero from '@components/content/hero/Hero';
import Loadable from '@loadable/component';
import './styles.scss';

const SignupForm = Loadable(() => import('@components/content/SignupForm'));

const SignUp = () => {
	useEffect(() => {
		if (window.location.href.indexOf('ed=1') > -1) {
			console.log('run ss');

			SignupForm.load().then(() => {
				setTimeout(() => {
					runScreenshots();
				}, 100);
			})
		}
	}, []);

	const runScreenshots = () => {
		var url = window.location.href;

		if (url.includes('ssFirstNameinput')) {
			document.getElementById('firstName').value = 'abc';
			console.log('firstName');
		}

		if (url.includes('ssLastNameinput')) {
			document.getElementById('lastName').value = '123';
			console.log('lastName');
		}

		if (url.includes('ssEmailAddressInput')) {
			document.getElementById('email').value = 'abc@klick.com';
			console.log('abcklic');
		}

		if (url.includes('ssZipCodeInput')) {
			document.getElementById('zipCode').value = 'M4W 3R8';
			console.log('zip');
		}
		if (url.includes('ssSignupError')) {
			const formFields = document.querySelectorAll('form .field');
			if (formFields) {
				formFields.forEach((field) => {
					field.classList.add('error');
				});
			}
		}
	};


	return (
		<Layout>
			<Seo title='Sign Up for Updates | EMFLAZA® (deflazacort)' description='Sign up to stay up to date on the latest news and clinical data. View full Prescribing Information & Important Safety Information.' />
			<div className='page--sign-up'>
				<Hero
					heroImage='hero-signup.png'
					heroImageMobile='hero-signup-mobile.png'
					title='Stay up to date on the latest news & clinical data'
					useBreadcrumbs={false}
				/>
				<section className='mt-8 md:mt-10'>
					<div className='container'>
						<p>Register today to receive the latest on EMFLAZA for Duchenne muscular dystrophy.</p>
						<p className='my-5'>All fields are required.</p>

						<SignupForm />
						<p className='mb-4 md:max-w-[720px] xl:mr-isi'>By clicking SUBMIT, you agree to the statement below and certify that you are at least 18 years of age and a US resident.</p>
						<p className='mb-4 md:max-w-[720px] xl:mr-isi'>By submitting this form, you agree to allow PTC Therapeutics to collect the information provided and to receive product and disease-state information from PTC, our affiliates, service providers, and co-promotion partners by phone, email, or mail. PTC or third-party partners working on our behalf will not sell, rent, or otherwise distribute your name and any personally identifiable information. We may share your information with our partners in accordance with our <KfAnchorLink url='https://www.ptcbio.com/terms-of-use' relationship='noopener' linkClass='underline' target='_blank'>Terms of Use</KfAnchorLink> and <KfAnchorLink url='https://www.ptcbio.com/privacy-statement' relationship='noopener' linkClass='underline' target='_blank'>Privacy Policy</KfAnchorLink>.</p>
						<p className='mb-6 md:max-w-[720px] xl:mr-isi md:mb-24'>You will have the opportunity to unsubscribe at any&nbsp;time.</p>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default SignUp;
